<template>
  <main>
    <v-card flat>
      <v-card-title>
        <div>
          Full Users List

          <v-btn icon class="ml-3" @click="getUsers" :disabled="!isAuthenticated || users.loading"><v-icon>{{ icons.mdiReload }}</v-icon></v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="tableColumns"
          disabled
          :footer-props="{
            'items-per-page-options': [10, 10],
            'disable-items-per-page': true,
            'disable-pagination': users.loading
          }"
          :items="users.list"
          :options.sync="users.options"
          :page="users.meta.page"
          :server-items-length="users.meta.total || 0"
          :loading="users.loading"
          @pagination="changePagination($event)"
          :no-data-text="'No data available'"
          :loading-text="'Loading, pls wait'"
          class="text-no-wrap"
        >
          <template #[`item.createdAt`]="{item}">
            {{ formatDate(item.createdAt) }}
          </template>

          <template #[`item.projects`]="{item}">
            {{ getProjectsValue(item) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </main>
</template>

<script>
import { mdiReload } from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data: () => ({
    icons: {
      mdiReload,
    },
    users: {
      loading: false,
      docs: [],
      meta: {},
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
    },
  }),
  computed: {
    ...mapGetters('web3auth', ['isAuthenticated', 'userdata']),
    tableColumns() {
      return [
        { text: 'CONNECTED', value: 'createdAt', sortable: false },
        { text: 'WALLET', value: 'wallet', sortable: false },
        { text: 'PROJECTS', value: 'projects', sortable: false },
        { text: 'IS_ADMIN', value: 'isAdmin', sortable: false },
        { text: 'LAUNCHPAD LAST CONNECTION', value: 'launchpad.lastConnection', sortable: false },
      ]
    },
  },
  methods: {
    getUsers(query) {
      if (!this.isAuthenticated) return

      this.users.loading = true

      const params = {
        limit: this.users.options.itemsPerPage,
        query: JSON.stringify(query),
        sort: { createdAt: 'desc' },
        ...params,
      }

      this.$http
        .get('admin/users', { params })
        .then(({ data }) => {
          const { meta, docs } = data

          this.users.meta = meta
          this.users.list = docs
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$nextTick(() => {
            this.users.loading = false
          })
        })
    },

    changePagination(pagination) {
      this.getUsers({
        page: pagination.page,
      })
    },

    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    },

    getProjectsValue(item) {
      if (!item.projects.length) return '-'

      return `[${item.projects.map(it => it.name)}]`
    },
  },
}
</script>
