var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('div',[_vm._v(" Full Users List "),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","disabled":!_vm.isAuthenticated || _vm.users.loading},on:{"click":_vm.getUsers}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiReload))])],1)],1)]),_c('v-card-text',[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"disabled":"","footer-props":{
          'items-per-page-options': [10, 10],
          'disable-items-per-page': true,
          'disable-pagination': _vm.users.loading
        },"items":_vm.users.list,"options":_vm.users.options,"page":_vm.users.meta.page,"server-items-length":_vm.users.meta.total || 0,"loading":_vm.users.loading,"no-data-text":'No data available',"loading-text":'Loading, pls wait'},on:{"update:options":function($event){return _vm.$set(_vm.users, "options", $event)},"pagination":function($event){return _vm.changePagination($event)}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.projects",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getProjectsValue(item))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }